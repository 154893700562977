import React,{Component} from 'react';
import { connect } from 'react-redux';
import { bindLifecycle } from 'utils/bindLifecycle';
import { LangConsumer } from 'utils/LangProvider';

import { Row, Col, Button, message } from 'antd';
import {Icon, LabelSelect, LabelText, Slider} from 'libs';
import {WorkTable} from './WorkTable';
import SmokeFireAreaPlugin from './AreaConfigurePlugin';
import { clone, transToChannelName } from '../../../../common';
import RPC from 'api';
import WebApp from '../../../../webapp';
import ability from '../../../../ability';
import './index.less';

const mapStateToProps = (state) => {
    const { publicState } = state;
    return { publicState };
};
// 场景类型翻译
const sceneTran = {
    OutdoorScene: 'ivs.ParkScene', // 园区场景
    // IndoorScene: 'com.IndoorScene', // 暂不用
    ForestScene: 'ivs.ForestScene', // 野外场景
};
// 规则下场景类型的值
const sceneTypeMap = {
    OutdoorScene: 'OutdoorScene', // 园区场景
    ForestScene: 'Forest', // 野外场景
};
class AreaConfig extends Component {
    /**用来存放组件内状态 */
    constructor (props) {
        super(props);
        this.ComponentName = 'AreaConfig';
        this.SmokeFireAreaPlugin = React.createRef();

        this.state = {
            channel: 0, //当前通道
            ptzId: 0, //预置点id
            presetOption: [], //预置点选项格式
            channelOption: [{value:0,text:'1'},{value:1,text:'2'}], //通道选项格式
            dataSource: [], // table数据源
            nowChooseRow: 0,
            oldArea: [], //记录 未改变过区域的 number => 好清空对应的规则
            presetName: '', //当前预置点名称
            // curPreset: WebApp.DeviceType.indexOf('SD') > -1 ? 1 : 0, //当前预置点
            // curChannel: 0 // 当前通道
            subSceneList: {}, // 支持的场景类型
            SupportZoomChange: {}, // 支持二次变倍
        };
        this.judge = {
            reflect (type) {
                if (type === 'isShowChannel') return WebApp.CHANNEL_NUMBER > 1; //是否显示视频上方的通道选项
                if (type === 'isShowPreset') return WebApp.DeviceType.indexOf('SD') > -1; //是否显示预置点
                if (type !== undefined) return this;
            }
        };
        this.g_global = []; //
        this.g_globalMuiltChannel = [];
        this.g_areaCountMax = 4; //最大区域数
        this.planType = 'SmokeFire'; // 智能规则类型 作业监测

        this.setDataSource = this.setDataSource.bind(this); //子组件调用，绑定上下文
        this.whenChooseTableRow = this.whenChooseTableRow.bind(this);
        this.whenTableNameWork = this.whenTableNameWork.bind(this);
        this.whenDeleteTableRow = this.whenDeleteTableRow.bind(this);
    }
    /**此时获取异步数据 */
    componentDidMount () {
        let { channel, ptzId } = this.state;
        const { pageChannels, channelToPresetObj } = this.props;
        // let channelOption = []; // 通道项
        let presetOption = []; // 预置点项
        // for (let item in channelToPresetObj) {
        //     if (channelToPresetObj[item] instanceof Array && channelToPresetObj[item].length > 0) {
        //         channelOption.push({value: item - 0, text: transToChannelName($t, item - 0)});
        //     }
        // }
        if (channelToPresetObj && channelToPresetObj[channel] instanceof Array) {
            for (let i = 0; i < channelToPresetObj[channel].length; i++) {
                presetOption.push({value: channelToPresetObj[channel][i], text: this.findPresetName(channelToPresetObj[channel][i])});
            }
        }
        presetOption = presetOption.sort((a, b) => a.value - b.value);
        if (presetOption.length > 0) {
            ptzId = presetOption[0].value;
            // this.goToPreset(ptzId);
        }
        let ivsCaps = WebApp.ChannelCapsInfo[channel];
        let scene = ivsCaps.SupportedScenes[this.planType];
        // 是否支持二次变倍
        let SupportZoomChange = {};
        Object.keys(scene.SupportedRules).forEach((item) => {
            SupportZoomChange[item] = scene.SupportedRules[item].SupportZoomChange;
        });
        // 获取场景类型选项
        let sceneList = scene?.SupportedSceneParams?.SupportSubSceneList;
        if (sceneList) {
            this.setState({ subSceneList: sceneList });
        }
        this.setState({ // 设置通道、预置点
            channel: pageChannels[0] ? pageChannels[0] : 0,
            channelOption: pageChannels.map(item => ({value:item,text:item + 1})),
            ptzId,
            presetOption,
            SupportZoomChange,
        }, () => {
            this.showPTZmenu();
            this._getAreaConfig();
            if (presetOption.length > 0) this.goToPreset(ptzId);
        });
    }
    componentDidActivate () {
        console.log('componentDidActivate');
        if (this.props.tabKey === '1') {
            this.goToPreset(this.state.ptzId); // 球机需要转动云台到对应的预置点
        }
    }
    // 配合父调用 useChild
    use (actionName, ...args) {
        const name = ['switchPlugin', 'init', 'leave'];
        if(name.includes(actionName)) {
            const { useChild } = this.props;
            useChild.call(this, '+' + actionName, ...args);
        } else {
            if(this[actionName] !== undefined) {
                this[actionName](...args);
            } else {
                console.error('no ' + actionName);
            }
        }
        return this;
    }
    setDataSource (dataSource, nowChooseRow) {
        if(nowChooseRow === undefined) this.setState({dataSource});
        else this.setState({dataSource, nowChooseRow});
    }
    /**
     * 转动到对应预置点
     * @param {Number} goPreset 需要转到的预置点
     */
    goToPreset = (goPreset) => {
        let {channel} = this.state;
        // 先等待锁定调用，原因：gotoPreset调用后会临时关闭智能，此时锁定会有一定概率失效
        setTimeout(() => {
            RPC.PTZManager('gotoPreset', channel, {'index': goPreset}).catch(err => {console.log(err);});
        }, 500);
    }
    // 找到当前通道、预置点所对应的全局配置 VideoAnalyseGlobal
    _findSceneKey = (channel, ptzId, VideoAnalyseGlobal) => {
        const data = VideoAnalyseGlobal[channel] || null;
        for(let key in data) {
            let value = data[key];
            if(key.indexOf('Scene') > -1 && value.Type === this.planType && value.PtzPresetId === ptzId) {
                console.log(key);
                return key;
            }
        }
        return '';
    }
    _getTableData (DetectRegions) {
        if (!DetectRegions) {
            return [];
        }
        // 获取当前智能对应区域
        // SceneDetectRegionType用来区分当前区域所对应的智能
        return DetectRegions.filter(
            item => item.SceneDetectRegionType && item.SceneDetectRegionType[0] === this.planType
        ).map(item => ({
            'DetectRegion': item.DetectRegion || [],
            'Number': item.Number,
            'Name': item.Name,
            'Type': 'ivs.FireSmokeDetection'
        }));
    }
    _getDetectRegions (dataSource) {
        // 其他智能检测区域
        let otherRegions = this.g_global[this.SceneKey].Detail.DetectRegions || [];
        otherRegions = clone(otherRegions.filter(
            item => item.SceneDetectRegionType && item.SceneDetectRegionType[0] !== this.planType)
        );
        // 当前智能检测区域
        let tableRegions = dataSource.map(item => ({
            'DetectRegion': item.DetectRegion,
            'Number': item.Number,
            'Name': item.Name,
            'SceneDetectRegionType': [this.planType] // 当前检测区域对应的场景类型
        }));
        // 合并后的检测区域
        return otherRegions.concat(tableRegions);
    }
    _renderElements (sceneOption) { //根据数据 更新页面视图--此页面仅有表格
        let dataSource = this._getTableData(sceneOption.Detail.DetectRegions);
        let oldArea = dataSource.map(item => item.Number);
        this.setState({dataSource, oldArea}, () => {
            this.props.useChild.call(this,'+renderPlugin');
            // 当前选中行超过区域列表长度
            if (dataSource.length <= this.state.nowChooseRow || this.state.nowChooseRow === -1) {
                this._chooseTableRow(0);
            }
            // if (dataSource.length > this.state.nowChooseRow && this.state.nowChooseRow === -1) {
            //     this._chooseTableRow(this.state.nowChooseRow); //有数据，默认选中首个
            // } else {
            //     this._chooseTableRow(0); //有数据，默认选中首个
            // }
        }); //每次重新渲染表格时，记录 oldArea
    }
    _getAreaConfig () { //获取区域配置并渲染整个页面
        let {channel, ptzId} = this.state;

        return RPC.ConfigManager.getConfig('VideoAnalyseGlobal').then(table => {
            this.g_global = table[channel];
            this.g_globalMuiltChannel = table;
            this.SceneKey = this._findSceneKey(channel, ptzId, table);
            if(!this.SceneKey) {
                console.error('no Scene');
                return true;
            }
            if (!this.g_global[this.SceneKey].Detail.DetectRegions) {
                this.g_global[this.SceneKey].Detail.DetectRegions = [];
            }
            this._renderElements( this.g_global[this.SceneKey] );
            return true;
        }).catch(err => {console.log(err); return false;});
    }
    _updateSceneFromClient () { //根据页面视图 更新数据
        if(!this.g_global || !this.g_global[this.SceneKey]) return;

        const {dataSource} = this.state;
        this.g_global[this.SceneKey].Detail.DetectRegions = this._getDetectRegions(dataSource);
        return this.g_global;
    }
    _chooseTableRow (index) {
        this.setState({ nowChooseRow: index }, () => {
            this.props.useChild.call(this,'+_SelectCurArea');
        });
    }
    changeChannel (v) { //切换通道
        this.setState({channel: v}, () => {
            this.showPTZmenu();
            this._getAreaConfig();
        });
    }
    /**
     * 切换预置点
     * @param {Number} preset 预置点
     */
    changePreset = (preset) => {
        let presetName = '';
        this.state.presetOption.map((item) => {
            if (item.value === preset) {
                presetName = item.text;
            }
        });
        this.setState({ptzId: preset, presetName}, () => {
            this.goToPreset(preset); // 球机需要转动云台到对应的预置点
            this.SceneKey = this._findSceneKey(this.state.channel, preset, this.g_globalMuiltChannel);
            this._renderElements(this.g_global[this.SceneKey]);
        });
    }
    // 修改场景类型
    changeSenceType = (v) => {
        this.g_global[this.SceneKey].Detail.SmokeFireSubScene = v;
        // console.log('*****changeSenceType*****');
        // console.log(v);
        // console.log(this.g_global);
        this.setState({}); // render
    }
    whenChooseTableRow (record, index) { //表格每行的事件
        return {
            onClick: () => this._chooseTableRow(index) //选中一行时
        };
    }
    whenTableNameWork (index, isSameName) { //修改表格名字生效时--index 修改哪一行, 是否重名
        const {$t} = this.props;
        if(isSameName) {
            message.warn($t('com.NameDuplication'));
            return;
        }
        this.props.useChild.call(this,'+renderPlugin');
    }
    whenDeleteTableRow (deleteItem, nextChooseRow) { //表格删除一行
        let {oldArea} = this.state;
        let oldAreaIndex = oldArea.indexOf(deleteItem.Number);
        if(~oldAreaIndex) oldArea.splice(oldAreaIndex, 1);
        this.setState({oldArea}, () => {
            this.props.useChild.call(this,'+renderPlugin');
        });
        // this._chooseTableRow(nextChooseRow);
    }
    _updateAllRule (DetectRegions, ClassType) { //更新每个区域下的规则 --删除新建区域后，对应规则清空
        const { oldArea, channel, ptzId, subSceneList } = this.state;
        const curSeneType = this.g_global[this.SceneKey].Detail.SmokeFireSubScene;
        const supportRules = subSceneList[curSeneType]; // 当前场景支持的规则
        RPC.ConfigManager.getConfig('VideoAnalyseRule').then(table => {
            // const numGather = DetectRegions.map(item => item.Number);
            let isUpdateRule = false;
            let ruleConfig;
            // 球机存在预置点，单独处理
            // 球机规则名称为区域名称
            if (WebApp.DeviceType.indexOf('SD') > -1) {
                // 规则是否对应当前预置点
                ruleConfig = table[channel].filter((item, idx) => {
                    if (item.PtzPresetId === ptzId && item.Class === ClassType) {
                        if (item.Config && oldArea.indexOf(item.Config.DectRegionNumber) === -1) {
                            isUpdateRule = true;
                            return false;
                        }
                        // 删除场景类型不支持的规则
                        if (supportRules && !supportRules.includes(item.Type)) {
                            isUpdateRule = true;
                            return false;
                        }
                    }
                    return true;
                });
                // 遍历列表，更新规则名称（区域名）
                for (let region of DetectRegions) {
                    ruleConfig.forEach((item, index) => {
                        if (item.PtzPresetId === ptzId && item.Class === ClassType && item.Config && item.Config.DectRegionNumber === region.Number) {
                            isUpdateRule = true;
                            ruleConfig[index].Name = region.Name;
                            ruleConfig[index].Config.DetectRegion = region.DetectRegion;
                            if (curSeneType) ruleConfig[index].Config.SceneType = sceneTypeMap[curSeneType] || curSeneType;
                        }
                    });
                }
            } else {
                // 规则所属的区域number 不在 oldArea之中，排除
                ruleConfig = table[channel].filter((item, idx) => {
                    if(item.Class === ClassType && item.Config && oldArea.indexOf(item.Config.DectRegionNumber) === -1) {
                        isUpdateRule = true;
                        return false;
                    }
                    // 删除场景类型不支持的规则
                    if (supportRules && !supportRules.includes(item.Type)) {
                        isUpdateRule = true;
                        return false;
                    }
                    return true;
                });
                // 遍历列表，更新规则名称（区域名）
                for (let region of DetectRegions) {
                    ruleConfig.forEach((item, index) => {
                        if (item.PtzPresetId === ptzId && item.Class === ClassType && item.Config && item.Config.DectRegionNumber === region.Number) {
                            isUpdateRule = true;
                            ruleConfig[index].Name = region.Name;
                            ruleConfig[index].Config.DetectRegion = region.DetectRegion;
                            if (curSeneType) ruleConfig[index].Config.SceneType = sceneTypeMap[curSeneType] || curSeneType;
                        }
                    });
                }
            }
            if(isUpdateRule) {
                console.log('*****更新ruleConfig*****');
                console.log(ruleConfig);
                RPC.ConfigManager.setConfig('VideoAnalyseRule', [ruleConfig]).catch(err => console.log(err));
            }
            return true;
        }).catch(err => {console.log(err); return true;});
    }
    /**
     * 查找预置点名称
     * @param {Number} presetID 预置点编号
     */
    findPresetName = (presetID) => {
        let {singleSDRuleData} = this.props;
        let presetName = '';
        for (let i = 0; i < singleSDRuleData.presets.length; i++) {
            if (singleSDRuleData.presets[i].Index === presetID) {
                presetName = singleSDRuleData.presets[i].Name;
                break;
            }
        }
        return presetName;
    }
    showPTZmenu () {
        let caps = WebApp.ChannelPTZCaps[this.state.channel];
        if (caps && Object.keys(caps).length > 0) {
            this.setState({
                isShowZoom: caps.Zoom !== false,
                isShowFocus: caps.Focus !== false,
                isShowIris: caps.Iris !== false
            });
        }else{
            this.setState({
                isShowZoom: false,
                isShowFocus: false,
                isShowIris: false
            });
        }
    }
    confirm () {
        const { $t } = this.props;
        const { dataSource } = this.state;
        let noAreaIndex = -1;
        if( dataSource.some((item, idx) => !item.DetectRegion.length && (noAreaIndex = idx, true)) ) {
            message.error($t('com.PleaseDrawRegionTip'));
            this._chooseTableRow(noAreaIndex);
            return;
        }
        this.g_global = this._updateSceneFromClient(); //更新 this.g_global
        RPC.ConfigManager.setConfig('VideoAnalyseGlobal', [this.g_global]).then(res => {
            this._updateAllRule(dataSource, this.planType);
            // 更新规则后更新oldArea
            this.setState({ oldArea: dataSource.map(item => item.Number) });
            this.props.useChild.call(this,'+renderPlugin'); // 重新绘制所有图形
            message.success($t('com.Operateingsuccess'));
        }).catch(err => {
            message.error($t('com.Operateingfailure'));
        });
    }
    refresh () {
        const { $t } = this.props;
        this._getAreaConfig().then(isOk => {
            if(isOk) {
                message.success($t('com.Operateingsuccess'));
            } else {
                message.error($t('com.Operateingfailure'));
            }
        });
    }
    default () {
        const { $t } = this.props;
        this.setState({dataSource: [], nowChooseRow: -1, oldArea: []}, () => {
            this.props.useChild.call(this,'+renderPlugin');
            message.success($t('com.Defaultsuccess'));
        });
    }
    render () {
        const { $t, langlocale, publicState, preStep} = this.props;
        let {
            channel, channelOption,
            presetOption, ptzId,
            dataSource,
            nowChooseRow,
            oldArea,
            presetName,
            isShowZoom, isShowFocus, isShowIris,
            subSceneList, SupportZoomChange
        } = this.state;
        const { whenChooseTableRow, whenTableNameWork, whenDeleteTableRow, setDataSource, _chooseTableRow} = this;

        const multiple = { type: 'normal', multiple: 1 };
        const {getGrid} = publicState;
        const englishColWidth = [{span : 9, xxl : 6}, {span : 9, xxl : 9}, {span : 5, xxl : 5, offset : 0}];
        let layOutColWidth = (langlocale.locale === 'zh-cn') ? publicState.getGrid(multiple).colWidth : englishColWidth;
        let SmokeFireSubScene = this.g_global[this.SceneKey]?.Detail?.SmokeFireSubScene;

        return (
            <div className = '_SmokeFireAreaConfig_'>
                {this.judge.reflect('isShowChannel') || this.judge.reflect('isShowPreset') ?
                    <React.Fragment>
                        <div className = 'topContent'>
                            <Row>
                                {this.judge.reflect('isShowChannel') ?
                                    <Col span={10}>
                                        <LabelSelect
                                            {...{controlWidth: [5, 12],
                                                value: channel,
                                                options: channelOption,
                                                label: $t('com.Channel'),
                                            }}
                                            onChange={(v) => this.changeChannel(v)}
                                            onDropdownVisibleChange={(isOpen) => this.props.useChild.call(this,'+hidePlugin',isOpen)}
                                        />
                                    </Col> : null
                                }
                                {this.judge.reflect('isShowPreset') ?
                                    <Col span={10}>
                                        <LabelSelect
                                            controlWidth={[5, 12]}
                                            value={ptzId}
                                            options={presetOption}
                                            label={$t('per.Preset')}
                                            onChange={(v) => {this.changePreset(v);}}
                                            onDropdownVisibleChange={(isOpen) => this.props.useChild.call(this,'+hidePlugin',isOpen)}
                                            // disabled={(webApp.DeviceType.indexOf('SD') > -1 && webApp.CHANNEL_NUMBER === 1) ? false : true}
                                        />
                                    </Col> : null
                                }
                            </Row>
                        </div>
                        <div style={{borderBottom: '1px solid #e8e8e8'}}></div>
                    </React.Fragment> : null
                }
                <div className = 'Content'>
                    <Row>
                        <Col span={12} style={WebApp.CHANNEL_TYPE[channel].indexOf('SD') > -1 ? {width:'475px'} : {width:'430px'}}>
                            <SmokeFireAreaPlugin ref={this.SmokeFireAreaPlugin}
                                {...{
                                    channel, nowChooseRow, dataSource, oldArea,
                                    ptzId, presetName, isShowZoom, isShowFocus, isShowIris,
                                    areaMax: this.g_areaCountMax
                                }}
                                {...{$t, setDataSource, _chooseTableRow}}
                                SupportZoomChange={Object.keys(SupportZoomChange).some(item => SupportZoomChange[item])}
                            />
                        </Col>
                        <Col span={12}>
                            {/* 区域列表 */}
                            <div className="config-wrap">
                                {/* 场景类型 */}
                                {WebApp.DeviceType.indexOf('SD') > -1 && SmokeFireSubScene ? <LabelSelect
                                    value={SmokeFireSubScene}
                                    label={$t('ivs.SceneType')}
                                    colWidth = {layOutColWidth}
                                    options={Object.keys(subSceneList).map(scene => {
                                        return {
                                            value: scene,
                                            text: $t(sceneTran[scene])
                                        };
                                    })}
                                    onChange={(v) => this.changeSenceType(v)}
                                /> : null}
                                <div className='image-title' style={{height: '40px', lineHeight: 'normal'}}>{$t('ivs.AreaConfigList')}</div>
                                <WorkTable
                                    {...{dataSource, nowChooseRow}}
                                    {...{
                                        $t, setDataSource, whenChooseTableRow,
                                        whenTableNameWork, whenDeleteTableRow
                                    }}
                                />
                                <div className="operation fn-width200">
                                    {!(WebApp.DeviceType.indexOf('SD') > -1) && <Button onClick={() => preStep('IVSSmokeFire')} className='fn-marr10' disabled={this.state.disabled}>{$t('com.Previous')}</Button>}
                                    <Button onClick = {() => this.confirm()} type = 'primary' className='fn-marr10 _submitButton'>{$t('com.Apply')}</Button>
                                    <Button onClick = {() => this.refresh()} className='fn-marr10 _refreshButton'>{$t('com.Refresh')}</Button>
                                    <Button onClick = {() => this.default()} className='fn-marr10 _defaultButton'>{$t('com.Comdefault')}</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps, null, null,{ forwardRef: true })(LangConsumer(bindLifecycle(AreaConfig)));